





























import { debounce } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataRole } from "@/models/interface/user.interface";
import { userServices } from "@/services/user.services";
import { Component, Vue, Watch } from "vue-property-decorator";

const RoleSelectProps = Vue.extend({
  props: {
    value: {
      type: Array as () => string[],
    },
    disabled: {
      type: Boolean,
    },
    defaultValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    query: {
      type: String,
    },
  },
});

@Component
export default class RoleSelect extends RoleSelectProps {
  roleOption = {
    data: [] as DataRole[],
    search: "",
    fetching: true,
    initialData: [] as DataRole[],
  };

  async created() {
    await this.getRoleList(true, this.query);
  }

  @Watch("defaultValue")
  onChangeDefaultValue(newValue: string): void {
    if (newValue && !this.roleOption.data.find(c => c.id === newValue)) {
      this.fetchMoreRole();
    }
  }

  onMeta(option: DataRole): void {
    this.$emit("meta", { meta: option });
  }

  async getRoleList(firstLoad = false, query?: string) {
    try {
      this.roleOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: query ?? "",
        sorts: "name:asc",
      };
      const res = await userServices.listOfRole(param);
      this.roleOption.data = res.data;
      this.$emit("on-getListRole", res.data);
      if (firstLoad) {
        this.roleOption.initialData = res.data;
      }
      this.roleOption.fetching = false;
    } catch (error) {
      this.roleOption.fetching = false;
    }
  }

  searchRole(value: string): void {
    debounce(() => {
      const searchBy: string[] = [];

      if (value) searchBy.push(`name~*${value}*_OR_description~*${value}*`);

      if (this.query) searchBy.push(this.query);

      this.getRoleList(false, searchBy.join("_AND_"));
    });
  }

  handleChange(e: string[]): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  /**
   * Fetch role list from API until matching with @this value
   */
  async fetchMoreRole() {
    try {
      this.roleOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
        search: this.query ?? "",
        sorts: "name:asc",
      };
      while (
        !this.roleOption.data.find(c => this.value.includes(c.id)) &&
        param.page <= totalPage
      ) {
        const roleOptions = await userServices.listOfRole(param);
        totalPage = Math.ceil(roleOptions.totalElements / 20);
        this.roleOption.data = this.roleOption.data.concat(roleOptions.data);
        param.page++;
      }
      this.$emit("on-getListRole", this.roleOption.data);
      this.roleOption.fetching = false;
    } catch (error) {
      this.roleOption.fetching = false;
    }
  }
}
